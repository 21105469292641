import type { FormProps } from "@remix-run/react";
import { Form } from "@remix-run/react";
import type { ReactNode } from "react";
import { useMemo } from "react";
import type { UseFormHandleSubmit } from "react-hook-form";

export const usePostForm = (
  handleSubmit?:
    | FormProps["onSubmit"]
    | UseFormHandleSubmit<
        Partial<{
          macAddress: string;
        }>
      >
    | undefined
) => {
  return useMemo(
    () =>
      ({ children, ...props }: { children?: ReactNode }) =>
        (
          // @ts-ignore
          <Form method="POST" onSubmit={handleSubmit} {...props}>
            {children}
          </Form>
        ),
    []
  );
};
